// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-code-editor-code-example-id-js": () => import("./../../../src/pages/code-editor/[codeExampleId].js" /* webpackChunkName: "component---src-pages-code-editor-code-example-id-js" */),
  "component---src-pages-courses-course-id-js": () => import("./../../../src/pages/courses/[courseId].js" /* webpackChunkName: "component---src-pages-courses-course-id-js" */),
  "component---src-pages-courses-js": () => import("./../../../src/pages/courses.js" /* webpackChunkName: "component---src-pages-courses-js" */),
  "component---src-pages-help-js": () => import("./../../../src/pages/help.js" /* webpackChunkName: "component---src-pages-help-js" */),
  "component---src-pages-help-support-id-js": () => import("./../../../src/pages/help/[supportId].js" /* webpackChunkName: "component---src-pages-help-support-id-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-notification-js": () => import("./../../../src/pages/notification.js" /* webpackChunkName: "component---src-pages-notification-js" */),
  "component---src-pages-security-js": () => import("./../../../src/pages/security.js" /* webpackChunkName: "component---src-pages-security-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-template-blog-blog-detail-wrapper-js": () => import("./../../../src/template/blog/BlogDetailWrapper.js" /* webpackChunkName: "component---src-template-blog-blog-detail-wrapper-js" */),
  "component---src-template-blog-tag-tag-detail-wrapper-js": () => import("./../../../src/template/blog/tag/TagDetailWrapper.js" /* webpackChunkName: "component---src-template-blog-tag-tag-detail-wrapper-js" */),
  "component---src-template-learning-lesson-detail-wrapper-js": () => import("./../../../src/template/learning/LessonDetailWrapper.js" /* webpackChunkName: "component---src-template-learning-lesson-detail-wrapper-js" */)
}

