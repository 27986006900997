import { createMuiTheme } from "@material-ui/core/styles";
import { green, grey, red } from "@material-ui/core/colors";

const rawTheme = createMuiTheme({
  palette: {
    primary: {
      light: "#69696a",
      main: "#1E88E5",
      dark: "#005CB2",
    },
    secondary: {
      light: "#fff5f8",
      main: "#1E88E5",
      dark: "#e62958",
    },
    warning: {
      main: "#ffc071",
      dark: "#ffb25e",
    },
    error: {
      xLight: red[50],
      main: red[500],
      dark: red[700],
    },
    success: {
      xLight: green[50],
      main: green[500],
      dark: green[700],
    },
  },
  typography: {
    fontFamily: "'Roboto', sans-serif",
    fontSize: 14,
    fontWeightLight: 300, // Work Sans
    fontWeightRegular: 400, // Work Sans
    fontWeightMedium: 700, // Roboto Condensed
    fontFamilySecondary: "'Roboto Condensed', sans-serif",
  },
  breakpoints: {
    values: {
      xxs: 0,
      xs: 400,
      sm: 768,
      md: 1024,
      lg: 1280,
      xl: 2000,
    },
  },
});

const fontHeader = {
  color: rawTheme.palette.text.primary,
  fontWeight: rawTheme.typography.fontWeightMedium,
  fontFamily: rawTheme.typography.fontFamilySecondary,
};

const theme = {
  ...rawTheme,
  palette: {
    ...rawTheme.palette,
    background: {
      ...rawTheme.palette.background,
      default: "#F5F5F6",
      placeholder: grey[200],
    },
  },
  typography: {
    ...rawTheme.typography,
    fontHeader,
    h1: {
      ...rawTheme.typography.h1,
      ...fontHeader,
      letterSpacing: 0,
      fontSize: 60,
    },
    h2: {
      ...rawTheme.typography.h2,
      ...fontHeader,
      fontSize: 48,
      "@media screen and (max-width: 767px)": {
        fontSize: "35px",
      },
    },
    h3: {
      ...rawTheme.typography.h3,
      ...fontHeader,
      color: rawTheme.palette.success.main,
      fontSize: 42,
    },
    h4: {
      ...rawTheme.typography.h4,
      ...fontHeader,
      fontSize: 30,
      fontWeight: rawTheme.typography.fontWeightBold,
      "@media screen and (min-width: 1024px)": {
        fontSize: "35px",
      },
    },
    h5: {
      ...rawTheme.typography.h5,
      fontSize: 20,
      fontWeight: rawTheme.typography.fontWeightBold,
      "@media screen and (min-width: 768px)": {
        fontSize: "22px",
      },
      "@media screen and (min-width: 1024px)": {
        fontSize: "25px",
      },
    },
    h6: {
      ...rawTheme.typography.h6,
      ...fontHeader,
      fontSize: 18,
    },
    subtitle1: {
      ...rawTheme.typography.subtitle1,
      fontSize: 14,
    },
    body1: {
      ...rawTheme.typography.body2,
      fontWeight: rawTheme.typography.fontWeightRegular,
      fontSize: 16,
      "@media screen and (min-width: 768px)": {
        fontSize: "18px",
      },
      "@media screen and (min-width: 1024px)": {
        fontSize: "20px",
      },
    },
    body2: {
      ...rawTheme.typography.body1,
      fontSize: 16,
      "@media screen and (min-width: 1024px)": {
        fontSize: "18px",
      },
    },
  },
};

export default theme;
